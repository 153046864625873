import useOtcTrades from "../../../../../core/hooks/main/orders/useOtcTrades";
import TableLayout from "../../../../layout/main/TableLayout";
import {Column, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {formatNumber, getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {BuyIcon, SellIcon, TradeAmount} from "../../../../../styles/main/orders";
import Text from "../../../../../core/utils/Text";
import DateTime from "../../../../common/utils/DateTime";
import Operation from "../../../../common/utils/Operation";
import UserLink from "../../../../common/utils/UserLink";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {otcOrdersAttributes} from "../../../../../core/constants/headers";


const OtcTradesTable = ({
    data
}) => {

    const {data: orders} = data
    const { cs, headers } = otcOrdersAttributes
    const { getTableIndex } = useGetTableIndex()

    // no write access needed - no actions
    const {
        onDetailsClicked
    } = useOtcTrades()


    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
        >
            {orders?.data?.map((order, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    order.user?.firstName,
                    order.user?.lastName
                )

                return (
                    <Row cs={cs} index={idx} key={order._id} hasParty={order.party?.result?.response?.code === '200000'}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={NAMEAVATAR}
                                    avatar={order.user?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={order.client?.userId}
                                name={FULLNAME}
                            />
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={SOCKET_URL + `assets/icon/${order.coin}.png`}
                                    alt={' '}
                                    width={'24px'}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {order.coin?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column>
                            <TradeAmount type={order.type}>
                                {formatNumber(order.amount)}
                            </TradeAmount>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(order.wage)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(order.priceUnit)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(order.price)}
                            </DText>
                        </Column>
                        <Column>
                            <Flex>
                                {order.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={order.type}>
                                        <Text tid={order.type} />
                                    </TradeAmount>
                                </Flex>
                            </Flex>
                        </Column>
                        <Column center>
                            <DateTime dt={order.tradedAt} />
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(order)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default OtcTradesTable
