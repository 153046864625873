import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Row} from "../../../../../styles/CommonStyles";
import useAvailableBanks from "../../../../../core/hooks/main/setting/general/useAvailableBanks";
import ABActionButton from "../ABActionButton";
import Tooltip from "../../../../common/utils/Tooltip";
import Toggle from "../../../../common/buttons/Toggle";
import {availableBanksAttributes} from "../../../../../core/constants/headers";


const AvailableBanksTable = () => {

    const { headers, cs } = availableBanksAttributes
    const {
        bankList,
        loading,
        onStatusChange,
        detailsChanged,
        onSubmitClicked
    } = useAvailableBanks()


    return (
        <>
            <div style={{ height: '30px' }} />
            <TableLayout
                headers={headers}
                cs={cs}
                data={{data: { data: bankList }, loading}}
                hasPagination={false}
            >
                {bankList?.map((bank, idx) => (
                    <Row cs={cs} index={idx}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            {bank.symbol}
                        </Column>
                        <Column>
                            {bank.name}
                        </Column>
                        <Column>
                            {bank.fa}
                        </Column>
                        <Column>
                            <Tooltip
                                content={bank.isActive ? 'is-active' : 'not-active'}
                            >
                                <Toggle
                                    onChange={() => onStatusChange(idx)}
                                    active={bank.isActive}
                                />
                            </Tooltip>
                        </Column>
                    </Row>
                ))}
            </TableLayout>
            <ABActionButton
                show={detailsChanged}
                onClick={onSubmitClicked}
                loading={loading}
            />
        </>


    )
}


export default AvailableBanksTable
