import { SOCKET_URL } from "../../../../../core/constants/urls"
import useWagesList from "../../../../../core/hooks/main/wages-list/useWagesList"
import { formatNumber } from "../../../../../core/utils/common"
import Text from "../../../../../core/utils/Text"
import { CMargin, DText, Flex, Row } from "../../../../../styles/CommonStyles"
import { MiniLineBreak, RespIndex } from "../../../../../styles/main/MainCommonStyles"
import Operation from "../../../../common/utils/Operation"
import Select from "../../../../common/dropdown/Select"
import RTableLayout from "../../../layout/RTableLayout"



const RWagesTable = () => {

        const {
            wages,
            wagesList,
            loading,
            availableCoins,
            coin,
            onCoinChange,
            onDetailsClicked
        } = useWagesList()

    return (
        <>
            <CMargin margin={'15px'} />
            <Select
                options={availableCoins}
                value={coin}
                onValueChange={onCoinChange}
                placeHolder={'search'}
                width={'280px'}
                height={'42px'}
                isCoin
            />
            <RTableLayout
                data={{data: wages, loading}}
                hasPagination={false}
            >
                {wages?.map((wage, idx) => (
                    <Row idx={idx} cs={'100%'} key={wage.currency}>
                        <Flex justify='space-between'>
                            <Flex>
                                <img
                                    src={
                                        wage.currency === 'irt' ?
                                        require('../../../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${wage.currency}.png`
                                    }
                                    width='32px'
                                    alt=' '
                                />
                                <DText style={{ margin: '0 8px' }}>
                                    {wage.currency?.toUpperCase()}
                                </DText>
                            </Flex>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(wage)}
                                hasDetails
                            />
                        </Flex>
                        <CMargin margin='10px' />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='count' /> :
                            </DText>
                            <DText primary fontSize='s'>
                                {formatNumber(wage.count)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='volume' /> :
                            </DText>
                            <DText primary fontSize='s' style={{ lineBreak: 'anywhere', width: '70%', direction: 'ltr' }}>
                                {formatNumber(wage.volumes)}
                            </DText>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify='space-between'>
                            <DText main fontSize='s'>
                                <Text tid='wage' /> :
                            </DText>
                            <DText primary fontSize='s' style={{ lineBreak: 'anywhere', width: '70%', direction: 'ltr' }}>
                                {formatNumber(wage.wages)}
                            </DText>
                        </Flex>
                        <RespIndex>
                            {idx + 1}
                        </RespIndex>
                    </Row>
                ))}


            </RTableLayout>
        </>
    )
}


export default RWagesTable
