import RTableLayout from "../../../layout/RTableLayout";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {LineBreak, RespIndex} from "../../../../../styles/main/MainCommonStyles";
import {getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import UserLink from "../../../../common/utils/UserLink";
import Text from "../../../../../core/utils/Text";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {useMainContext} from "../../../../../core/contexts/main";


const RAddressesTable = ({
    data
}) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: addresses } = data
    const { lang } = useMainContext()

    return (
        <RTableLayout
            data={data}
        >
            {addresses?.data?.map((address, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(address.user.firstName, address.user.lastName)

                return (
                    <Row
                        key={address._id}
                        index={idx}
                        cs={'100%'}
                    >

                        <Flex fw justify={'flex-start'}>
                            <Avatar
                                avatar={address.user.avatar}
                                name={NAMEAVATAR}
                            />
                            <UserLink
                                _id={address.user._id}
                                name={FULLNAME}
                            />
                        </Flex>
                        <CMargin margin={'4px'} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'coin'} /> :
                            </DText>
                            <Flex>
                                <img
                                    src={SOCKET_URL + `assets/icon/${address.coin}.png`}
                                    alt={' '}
                                    width={'24px'}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {address.coin?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Flex>
                        <LineBreak />
                        <Flex fw justify={'space-between'}>
                            <Flex width={'30%'} justify={'flex-start'}>
                                <DText main fontSize={'ss'}>
                                    <Text tid={'address'} /> :
                                </DText>
                            </Flex>
                            <Flex width={'70%'}>
                                <DText primary fontSize={'ss'} style={{ lineBreak: 'anywhere', textAlign: lang === 'en' ? 'right' : 'left' }}>
                                    {address.address}
                                </DText>
                            </Flex>
                        </Flex>
                        <RespIndex>
                            {getTableIndex(idx)}
                        </RespIndex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}


export default RAddressesTable
