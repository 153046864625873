import SettingCard from "../../components/layout/main/SettingCard";
import ListLayout from "../../components/layout/main/ListLayout";
import TradesPanel from "../../components/main/panel/trades/TradesPanel";
import OrdersPanel from "../../components/main/panel/orders/OrdersPanel";
import {CFlex} from "../../styles/CommonStyles";
import UserPieCharts from "../../components/main/panel/users/UserPieCharts";


const Dashboard = () => {


    return (
        <ListLayout>
            <CFlex fw>
                <UserPieCharts />

                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    noTitle
                    main
                >
                    <TradesPanel />
                </SettingCard>
                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    noTitle
                    main
                >
                    <OrdersPanel />
                </SettingCard>

            </CFlex>
        </ListLayout>
    )
}

export default Dashboard
