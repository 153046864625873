import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useEffect, useState} from "react";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {AnimatePresence} from "framer-motion";
import AvailableBanks from "../../../components/main/setting/general/available-banks/Index";
import DollarFee from "../../../components/main/setting/general/dollar-fee/DollarFee";
import AffiliateSetting from "../../../components/main/setting/general/affiliate/AffiliateSetting";
import BlockAccess from "../../../components/main/setting/general/block-access/BlockAccess";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../core/constants/common";
import GeneralSettings from "../../../components/main/setting/general/general/GeneralSettings";
import MoveToMaster from "../../../components/main/setting/general/move-to-master/MoveToMaster";
import {useAclContext} from "../../../core/contexts/acl";


const TABS = ['dollar-fee', 'available-banks', 'affiliates', 'access-block', 'move-to-master']
const RESP_TABS = [...TABS, 'overall']

const General = () => {

    const [desktopTabs, setDesktopTabs] = useState(TABS)
    const { width } = useWindowSize()

    const { permissions } = useAclContext()

    useEffect(() => {
        if (permissions) {
            if (permissions.moveToMaster?.write) {
                let newTabs = [...TABS]
                newTabs.push('move-to-master')
                setDesktopTabs(newTabs)
            }
        }
    }, [permissions])

    /**
     * tabs => 1) dollar - 2) available banks - 3) affiliates 4) block access 5) move-to-master 6) general
     */
    const [activeTab, setActiveTab] = useState(1)

    const onTabChange = (idx) => {
        setActiveTab(idx)
    }


    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={RESP_TABS}
                    active={activeTab}
                    onTabClicked={onTabChange}
                />
                <AnimatePresence exitBeforeEnter>
                    {activeTab === 1 ?
                        <DollarFee />
                        : (
                            activeTab === 2 ?
                                <AvailableBanks />
                                : (
                                    activeTab === 3 ?
                                        <AffiliateSetting />
                                        :
                                        activeTab === 4 ?
                                            <BlockAccess />
                                            :
                                            activeTab === 5 ?
                                                <MoveToMaster />
                                                :
                                                <GeneralSettings />
                                )
                        )
                    }
                </AnimatePresence>
            </PageCard>
        </ListLayout>
    )
}

export default General;
