import { useMutation, useQuery } from "react-query";
import {normalFetch, getPing} from "../../fetch-api/get";
import { putApi } from '../../fetch-api/put'
import { useQueryContext } from "../../../contexts/query"
import {postApi} from "../../fetch-api/post";


const SETTING_KEYS = {
    AVAILABLE_COINS: 'available-coins',
    SET_AVAILABLE_COINS: 'set-available-coins',
    PREDEFINED_COINS: 'predefined-coins',
    PING: 'ping',
    MARKET: 'market',
    GET_CONFIG_ITEMS: 'get-config-items',
    SET_CONFIG_ITEMS: 'set-config-items',
    GET_THIRD_CONFIG: 'get-third-config',
    MOVE_T0_MASTER: 'move-to-master',
    SET_COIN_STATIC: 'set-coin-static'
}


const useAvailableCoins = () => {

    return useQuery(
        SETTING_KEYS.AVAILABLE_COINS, () => normalFetch('settings/coins'),
        {
            staleTime: 5 * 60 * 1000,
            cacheTime: 5 * 60 * 1000,
            select: res => res?.data?.data,
        }
    )
}


const useSetAvailableCoins = (onSuccess) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_AVAILABLE_COINS, (data) => putApi({ data }, 'settings/coins'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.AVAILABLE_COINS)
                onSuccess && onSuccess()
                setToast({
                    show: true, message: 'operation-success'
                })
            }
        }
    )
}


const useGetPredefinedCoins = () => {

    return useQuery(
        SETTING_KEYS.PREDEFINED_COINS, () => normalFetch('settings/coins/defined'),
        {
            select: res => res?.data?.data,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    )
}


const useGetConfigItems = () => {

    return useQuery(
        SETTING_KEYS.GET_CONFIG_ITEMS, () => normalFetch('settings/items'),
        {
            select: res => res?.data?.data,
            staleTime: 5 * 60 * 1000,
            cacheTime: 5 * 60 * 1000
        }
    )
}


const useSetConfigItems = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        SETTING_KEYS.SET_CONFIG_ITEMS, (data) => putApi(data,'settings/items'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(SETTING_KEYS.GET_CONFIG_ITEMS)
                setToast({
                    show: true, message: 'operation-success'
                })
            }
        }
    )
}


const useGetThirdConfig = () => {

    return useQuery(
        SETTING_KEYS.GET_THIRD_CONFIG, () => normalFetch('configs/settings/item'),
        {
            select: res => res?.data?.data,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    )
}


const useMoveToMaster = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        SETTING_KEYS.MOVE_T0_MASTER, (data) => postApi(data, 'settings/wallet'),
        {
            onSuccess: () => {
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}


const useSetCoinStaticPrices = () => {

    return useMutation(
        SETTING_KEYS.SET_COIN_STATIC, (data) => putApi(data, 'settings/coins/price')
    )
}

const useMarket = () => {

    return useQuery(
        SETTING_KEYS.MARKET, () => normalFetch('market'),
        {
            select: res => res?.data?.data
        }
    )
}


const usePing = () => {

    return useQuery(
        SETTING_KEYS.PING, () => getPing(),
        {
            retry: 0
        }
    )
}

export {
    useAvailableCoins,
    useSetAvailableCoins,
    useGetPredefinedCoins,
    useGetConfigItems,
    useSetConfigItems,
    useGetThirdConfig,
    useMoveToMaster,
    usePing,
    useMarket,
    useSetCoinStaticPrices
}
