import TableLayout from "../../../layout/main/TableLayout";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import {Column, DText, Flex, Row} from "../../../../styles/CommonStyles";
import {getNames} from "../../../../core/utils/common";
import Avatar from "../../../common/utils/Avatar";
import UserLink from "../../../common/utils/UserLink";
import {SOCKET_URL} from "../../../../core/constants/urls";
import DateTime from "../../../common/utils/DateTime";
import {addressAttributes} from "../../../../core/constants/headers";


const AddressesTable = ({
    data
}) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: addresses } = data
    const { cs, headers } = addressAttributes

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {addresses?.data?.map((address, idx) => {
                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(address.user.firstName, address.user.lastName)

                return (
                    <Row index={idx} key={address._id} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Flex>
                                <Avatar
                                    avatar={address.user.avatar}
                                    name={NAMEAVATAR}
                                />
                                <UserLink
                                    _id={address.userId}
                                    name={FULLNAME}
                                />
                            </Flex>
                        </Column>
                        <Column>
                            <Flex>
                                <img
                                    src={SOCKET_URL + `assets/icon/${address.coin}.png`}
                                    alt={' '}
                                    width={'24px'}
                                />
                                <DText main style={{ margin: '0 8px' }}>
                                    {address.coin?.toUpperCase()}
                                </DText>
                            </Flex>
                        </Column>
                        <Column style={{lineBreak: 'anywhere'}}>
                            {address.address}
                        </Column>
                        <Column center>
                            <DateTime dt={address.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default AddressesTable
