/**
 *
 * @param {string} theme
 * @param {string} lang
 * @return {Object}
 */
const getMainTheme = (theme, lang) => {

    const MainTheme = {
        dark: {
            mainBg: "#191c20",
            secondaryBg: "#13171A",
            primaryBg: "#161a1e",
            color: "#c3c5b7",
            secondary: "#eaecef",
            primary: "white",
            hover: "#26282b70",
            active: "#f0b90b",
            exitRed: "#a12356",
            mainOrange: "#ffc800",
            mainRed: "#e9106c",
            mainGreen: "#1ce087",
            search: "#161a1e",
            input: "#3f4243",
            textArea: "#44464c",
            dropdown: "#36383C",
            tradeBg: "#1e2026",
            tInputBg: "#2a2d35",
            otcInput: "#0e111a",
            mChart: "#131722",
            orderHover: "#5e6673",
            inActive: '#72747830',
            shadow: "#000",
            skeletonBg: "#1e2026",
            skeleton: "#2a2d35",
            shimmer: "rgba(255,255,255, 0.05)",
            sortHeader: "#212427"
        },
        light: {
            mainBg: "#fafafa",
            secondaryBg: "white",
            primaryBg: "white",
            color: "#191c20",
            secondary: "#595e67",
            primary: "#595e67",
            hover: "#fafafa70",
            active: "#f0b90b",
            exitRed: "#a12356",
            mainOrange: "#f0b90b",
            mainRed: "#f6465d",
            mainGreen: "#37b88b",
            search: "#b7bdc6",
            input: "#fafafa",
            textArea: "#f1f2f3",
            dropdown: "#707a8a",
            tradeBg: "#ffffff",
            tInputBg: "#f0f1f2",
            otcInput: "#f0f1f2",
            mChart: "wheat",
            orderHover: "#f0f1f2",
            inActive: '#72747830',
            shadow: "#00000060",
            skeletonBg: "#f2f2f2",
            skeleton: "#ddd",
            shimmer: "rgba(255,255,255, 0.2)",
            sortHeader: "#f8f8f8"
        }
    };

    const chosenTheme = { ...MainTheme[theme] }
    chosenTheme.english = lang === "en"
    return chosenTheme
}


const fadeVariants = {
    out: {
        opacity: 0, transition: { duration: 0.3 }
    },
    in: {
        opacity: 1, transition: { duration: 0.3 }
    }
}


const getRandomColor = (idx) => {
    const randomColor = [
        '#818F9F', '#9F819E', '#9F9181', '#819F82'
    ]
    return randomColor[idx % 3]
}

const getTradeTypeColor = (type) => {
    const typedColors = {
        oco: '#819F82',
        stop: '#9F819E',
        market: '#9F9181',
        limit: '#818F9F',
        pending: '#9F9181',
        success: '#819F82',
        error: '#9F819E',
        canceled: '#9F9181',
        timeout: '#9F819E',
        order: '#819F82',
        admin: '#9F819E',
        withdraw: '#9F9181',
        deposit: '#818F9F',
        otc: '#9F9181',
        buy: '#819F82',
        sell: '#9F819E',
        banking: '#9F819E',
        coining: '#9F819E',
        maker: '#819F82',
        taker: '#9F9181',
        completed: '#819F82',
        increase: '#819F82',
        decrease: '#9F819E',
        rejected: '#9F819E',
        done: '#819F82',
        day: '#9F9181',
        month: '#9F819E',
        week: '#818F9F',
        total: '#819F82'
    }

    return typedColors[type]
}


export {
    getMainTheme,
    fadeVariants,
    getRandomColor,
    getTradeTypeColor
}
