import {useGetAvailableBanks, useSetAvailableBanks} from "../../../../services/react-query/setting/available-banks";
import {useEffect, useState} from "react";
import {deepCopy} from "../../../../utils/common";


const useAvailableBanks = () => {

    const [ detailsChanged, setDetailsChanged ] = useState(false)
    const { data: banks, isFetching: loadingBanks} = useGetAvailableBanks()
    const { mutate: setAvailableBanks, isLoading: settingBanks } = useSetAvailableBanks()

    const [bankList, setBankList] = useState([])

    const resetChanges = () => {
        if (banks) {
            setBankList(banks.banks)
        }
    }

    useEffect(() => {
        resetChanges()
    }, [banks])

    const changeDetailState = () => {
        const cache = banks.banks
        let hasChange = false
        for (let i = 0; i < bankList.length; i++) {
            if (cache[i].isActive !== bankList[i].isActive) {
                hasChange = true
                break
            }
        }
        setDetailsChanged(hasChange)
    }

    const onStatusChange = (idx) => {
        const newBanks = deepCopy(bankList)
        newBanks[idx].isActive = !newBanks[idx].isActive
        setBankList(newBanks)
    }

    useEffect(() => {
        if (bankList.length) {
            changeDetailState()
        }
    }, [bankList])

    const onSubmitClicked = () => {
        const newBanks = deepCopy(banks)
        newBanks.banks = bankList
        setAvailableBanks({data: newBanks})
    }

    return {
        bankList,
        loading: loadingBanks || settingBanks,
        onStatusChange,
        detailsChanged,
        onSubmitClicked
    }
}


export default useAvailableBanks
