import TableLayout from "../../../../layout/main/TableLayout";
import useCurrentOrders from "../../../../../core/hooks/main/orders/useCurrentOrders";
import {
    Column,
    DText,
    Flex,
    Row
} from "../../../../../styles/CommonStyles";
import Avatar from "../../../../common/utils/Avatar";
import CoinMarket from "../../../../common/common/CoinMarket";
import {TradeTypeBadge, TradeAmount, BuyIcon, SellIcon} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import {formatNumber, getNames} from "../../../../../core/utils/common";
import Text from "../../../../../core/utils/Text";
import DateTime from "../../../../common/utils/DateTime";
import Operation from "../../../../common/utils/Operation";
import UserLink from "../../../../common/utils/UserLink";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {currentOrderAttributes} from "../../../../../core/constants/headers";


const CurrentOrders = ({
    data
}) => {

    const {data: orders} = data
    const { cs, headers } = currentOrderAttributes
    const { getTableIndex } = useGetTableIndex()
    const {
        hasWriteAccess,
        onDetailsClicked,
        onDeleteClicked,
        modal,
        onModalClose,
        deletingOrder,
        onSubmitClicked
    } = useCurrentOrders()


    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {orders?.data?.map((order, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    order.user?.firstName,
                    order.user?.lastName
                )

                let percent = 0
                if (order.tradedAmount) {
                    percent = (order.tradedAmount / (order.amount + order.tradedAmount)) * 100
                }

                return (
                    <Row
                        key={order._id}
                        cs={cs}
                        index={idx}
                        percent={percent}
                        type={order.type}
                        currentOrder
                    >
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={NAMEAVATAR}
                                    avatar={order.user?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={order.userId}
                                name={FULLNAME}
                            />
                        </Column>
                        <Column>
                            <CoinMarket
                                coin={order.coin}
                                pair={order.pair}
                            />
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(order.submit)}>
                                {order.submit}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <TradeAmount type={order.type}>
                                {formatNumber(order.amount + order.tradedAmount)}
                            </TradeAmount>
                        </Column>
                        <Column>
                            <TradeAmount type={order.type}>
                                {formatNumber(order.tradedAmount)}
                            </TradeAmount>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(order.priceUnit)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(order.price)}
                            </DText>
                        </Column>
                        <Column>
                            <Flex>
                                {order.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={order.type}>
                                        <Text tid={order.type} />
                                    </TradeAmount>
                                </Flex>
                            </Flex>
                        </Column>
                        <Column center>
                            <DateTime dt={order.createdAt} />
                        </Column>
                        <Column>
                            <Operation
                                onDeleteClicked={() => onDeleteClicked(order)}
                                onDetailsClicked={() => onDetailsClicked(order)}
                                hasWriteAccess={hasWriteAccess}
                                deleteTooltip={'delete-order'}
                                hasDetails
                                hasDelete
                            />
                        </Column>
                    </Row>
                )
            })}
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={'delete-order-head'}
                    desc={'delete-order-desc'}
                    _id={modal.order?._id}
                    onClose={onModalClose}
                    onSubmit={onSubmitClicked}
                    loading={deletingOrder}
                />
            </ModalLayout>
        </TableLayout>
    )
}





export default CurrentOrders
