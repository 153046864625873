import TableLayout from "../../layout/main/TableLayout";
import {Column, DText, Flex, Relative, Row} from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import Avatar from "../../common/utils/Avatar";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getRandomColor, getTradeTypeColor} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import {formatCardNumber, getNames} from "../../../core/utils/common";
import Operation from "../../common/utils/Operation";
import {bankAccountOperations} from "../../../core/constants/operation";
import {useMainContext} from "../../../core/contexts/main";
import UserBankActions from "../users/UserBankActions";
import useBankAccounts from "../../../core/hooks/main/bank-accounts/useBankAccounts";
import UserLink from "../../common/utils/UserLink";
import {bankAccountAttributes} from "../../../core/constants/headers";


const BankAccountsTable = ({ data }) => {

    const { lang } = useMainContext()
    const { cs, headers } = bankAccountAttributes
    const { getTableIndex } = useGetTableIndex()
    const { data: banks } = data

    const {
        hasWriteAccess,
        actionMode,
        closeActionMode,
        onOptionClicked
    } = useBankAccounts()

    return (
        <>
            {actionMode.show ?
                <UserBankActions
                    mode={actionMode}
                    closeActionMode={closeActionMode}
                    kycMode
                />
                :
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={data}
                    hasWriteAccess={hasWriteAccess}
                >
                    {banks?.data?.map(bank => {
                        const { FULLNAME } = getNames(bank.firstName, bank.lastName)
                        return (
                            <>
                                {bank.banks?.map((item, idx) => (
                                    <Relative>
                                        <Row index={idx} cs={cs}>
                                            <Column>
                                                {getTableIndex(idx)}
                                            </Column>
                                            <Column>
                                                <Flex>
                                                    <Avatar
                                                        avatar={bank.avatar}
                                                        name={bank.firstName}
                                                    />
                                                    <UserLink
                                                        _id={bank._id}
                                                        name={FULLNAME}
                                                    />
                                                </Flex>
                                            </Column>
                                            <Column>
                                                <TradeTypeBadge
                                                    color={getTradeTypeColor(bank.verifyAt ? 'success' : 'error')}>
                                                    <Text tid={bank.verifyAt ? 'verified' : 'not-verified'}/>
                                                </TradeTypeBadge>
                                            </Column>
                                            <Column style={{letterSpacing: '1.2px'}}>
                                                {formatCardNumber(item.cardNo)}
                                            </Column>
                                            <Column style={{letterSpacing: '1.1px'}}>
                                                {item.shebaNo}
                                            </Column>
                                            <Column>
                                                <TradeTypeBadge color={getRandomColor(idx % 3)}>
                                                    {item.label?.substring(0, 10)}
                                                </TradeTypeBadge>
                                            </Column>
                                        </Row>
                                        <Column operation style={{top: '40%', left: lang === 'fa' && '20px'}}>
                                            <Operation
                                                options={bankAccountOperations}
                                                onOptionClicked={(idx) => onOptionClicked(idx, item, bank._id)}
                                                hasWriteAccess={hasWriteAccess}
                                            />
                                        </Column>
                                    </Relative>
                                ))}
                            </>
                            )})}
                </TableLayout>
            }
        </>
    )
}


export default BankAccountsTable
